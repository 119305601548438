


































































import { Component, Vue } from 'vue-property-decorator';
import { HistoryContent } from '@/types/neuhaus-website/cms/pages/history';
import axios, { APIResponse } from '@/plugins/axios';
import AlertModule, { AlertType } from '@/store/modules/alert';

/**
 * CMSAdminHistory component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'history-timeline': () => import('@/components/neuhaus-website/cms/admin/history/timeline/HistoryTimelineList.vue')
    }
})
export default class CMSAdminHistory extends Vue {
    // variable with form data
    private formData: HistoryContent = {
        id: -1,
        title: '',
        timeline: {
            id: -1,
            items: []
        }
    }

    // Boolean that defines wheather form is valid or not
    private formValid = false;

    /**
     * Function will be executed on component load
     * Fetches data with fetchPageContent function
     */
    private async created() {
        this.formData = await this.fetchPageContent();
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Fetches page content from API
     * 
     * @returns HistoryContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<HistoryContent> {
        try {
            const response = await axios.get<APIResponse<HistoryContent>>('/cms/pages/history')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.formData;
        }
    }

    /**
     * Saves form data
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveFormData() {
        axios.put<APIResponse>('/cms/pages/history', this.formData)
            .then(response => {
                if (response.data.status === 'success' && response.data.data) {
                    this.resetFormValidation();
                    this.$router.push({ name: 'cmsAdminPages' });
                }
                else if (response.data.status === 'error' && response.data.message) {
                    const errorMessage = this.$t('alerts.errors.' + response.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });
                }
            });
    }
}
